import React, { Component } from 'react';
import Layout from '../components/layout';
import BackgroundCanvas from '../components/background-canvas-component';

import './terms.scss';

class TermsPage extends Component {
    render() {
        return (
            <Layout>
                <div className="terms_wrapper">
                    <BackgroundCanvas />
                    <h2>Terms and Agreements</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis, ipsum sed pretium
                        porttitor, sapien nunc consequat lorem, eu mollis orci neque sed enim. Mauris consectetur nunc
                        non massa facilisis imperdiet. Nam varius faucibus viverra. Class aptent taciti sociosqu ad
                        litora torquent per conubia nostra, per inceptos himenaeos. Ut ac lobortis dui. Nunc in rhoncus
                        velit. Mauris nulla tortor, volutpat nec sapien ac, tincidunt sodales ex. Cras scelerisque
                        elementum feugiat. Donec lacinia, lectus mollis auctor euismod, libero elit suscipit arcu, quis
                        tincidunt nisi turpis et ligula. Aenean non est vitae mi scelerisque consectetur quis eu nulla.
                        Pellentesque in elit suscipit libero porttitor rutrum vel sit amet augue. In eget elit accumsan,
                        rhoncus nisl egestas, lobortis dolor. Suspendisse maximus a lorem a condimentum. Quisque rutrum
                        odio pretium tortor venenatis, quis scelerisque nisl fringilla. Maecenas euismod, lacus at
                        imperdiet luctus, felis libero accumsan quam, vitae dignissim leo lectus eu elit. Etiam feugiat
                        dolor tempus massa pulvinar, vitae placerat ante mollis. Nam hendrerit turpis eget consequat
                        finibus. In fermentum, erat non interdum rutrum, turpis purus porttitor felis, nec ultrices
                        felis diam in metus. Nam eu turpis eu urna blandit maximus ac eu neque. In imperdiet arcu at
                        tincidunt lobortis. Proin purus felis, tristique sit amet orci eget, pulvinar viverra arcu.
                        Praesent semper felis a tellus congue, vitae bibendum eros commodo. Nunc eu metus vitae dolor
                        molestie malesuada. Praesent dapibus pellentesque velit, in eleifend libero. Nam ut augue
                        ullamcorper, pellentesque orci vitae, accumsan nunc. Mauris fringilla auctor pulvinar. Etiam eu
                        mi mauris. Etiam urna mi, ultricies vel scelerisque sit amet, dictum sed nulla. Donec orci erat,
                        gravida non varius eget, finibus et felis. Pellentesque ipsum libero, bibendum eget bibendum
                        finibus, blandit eu lectus. Sed vel convallis nulla. Sed aliquet viverra est non malesuada.
                        Aliquam sem sem, aliquet placerat risus at, volutpat vulputate magna. Duis eu feugiat eros.
                        Suspendisse placerat venenatis est, vel posuere massa cursus sit amet. Sed congue justo ut
                        feugiat ornare. Nunc et condimentum purus, vel ultrices dui. Curabitur non dui arcu. Fusce eu
                        sem eget dolor convallis eleifend. Duis gravida fringilla odio, at porta dui imperdiet eget.
                        Maecenas eleifend orci et mattis ultricies. Nam dolor enim, bibendum sit amet scelerisque sit
                        amet, tincidunt quis eros. Etiam fermentum mauris ut porta semper. Aenean imperdiet leo non orci
                        convallis, nec ultrices diam gravida. Maecenas feugiat neque dui, bibendum suscipit arcu
                        consectetur id. Ut turpis massa, sollicitudin vel rhoncus vitae, bibendum non lorem. Suspendisse
                        iaculis porta mi. Donec in quam augue. Quisque convallis imperdiet nisi, in consequat nisl
                        feugiat a. Suspendisse potenti. Integer ultricies magna nec risus molestie lacinia. In turpis
                        nulla, volutpat varius risus et, maximus interdum ante. Vestibulum magna sapien, ultricies
                        euismod vestibulum non, dictum sit amet odio. Aliquam blandit hendrerit arcu ac mollis. Sed
                        aliquam accumsan nisl. Donec posuere vitae ligula eget accumsan. Sed varius nibh eros, eget
                        tincidunt justo tincidunt sed. Duis porttitor aliquet dictum.
                    </p>
                </div>
            </Layout>
        );
    }
}

export default TermsPage;
